.header .header-2-3 .modal-backdrop.show {
  background-color: #707092;
  opacity: 0.6;
}

.header .header-2-3 .modal-item.modal {
  top: 2rem;
}

.header .header-2-3 .navbar,
.header .header-2-3 .hero {
  padding: 3rem 2rem;
}

.header .header-2-3 .navbar-dark .navbar-nav .nav-link {
  font: 300 18px/1.5rem Poppins, sans-serif;
  color: #707092;
  transition: 0.3s;
}

.header .header-2-3 .navbar-dark .navbar-nav .nav-link:hover {
  font: 600 18px/1.5rem Poppins, sans-serif;
  color: #e7e7e8;
  transition: 0.3s;
}

.header .header-2-3 .navbar-dark .navbar-nav .active>.nav-link,
.header .header-2-3 .navbar-dark .navbar-nav .nav-link.active,
.header .header-2-3 .navbar-dark .navbar-nav .nav-link.show,
.header .header-2-3 .navbar-dark .navbar-nav .show>.nav-link {
  font-weight: 600;
  transition: 0.3s;
}

.header .header-2-3 .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header .header-2-3 .btn:focus,
.header .header-2-3 .btn:active {
  outline: none !important;
}

.header .header-2-3 .btn-fill {
  font: 600 18px / normal Poppins, sans-serif;
  background-color: #524eee;
  border-radius: 12px;
  padding: 12px 32px;
  transition: 0.3s;
}

.header .header-2-3 .btn-fill:hover {
  --tw-shadow: inset 0 0px 25px 0 rgba(20, 20, 50, 0.7);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: 0.3s;
}

.header .header-2-3 .btn-no-fill {
  font: 300 18px/1.75rem Poppins, sans-serif;
  color: #e7e7e8;
  padding: 12px 32px;
  transition: 0.3s;
}

.header-2-3 .btn-no-fill:hover {
  color: #e7e7e8;
}

.header-2-3 .modal-item .modal-dialog .modal-content {
  border-radius: 8px;
  transition: 0.3s;
}

.header-2-3 .responsive li a {
  padding: 1rem;
  transition: 0.3s;
}

.header-2-3 .left-column {
  margin-bottom: 2.75rem;
  width: 100%;
}

.header-2-3 .text-caption {
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.025em;
  font-family: "Chapeau", sans-serif;
}

.header .header-2-3 .title-text-big {
  font: 600 2rem/2.2.5rem Poppins, sans-serif;
  color: #cbcbd2;
}

.header .header-2-3 .btn-try {
  font: 600 1rem/1.5rem Poppins, sans-serif;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  background-color: #524eee;
  transition: 0.3s;
}

.header .text-gray-1 {
  color: #adadad;
}

.header .header-2-3 .btn-try:hover {
  --tw-shadow: inset 0 0px 25px 0 rgba(20, 20, 50, 0.7);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: 0.3s;
}

.header .header-2-3 .btn-outline {
  font: 400 1rem/1.5rem Poppins, sans-serif;
  border: 1px solid #707092;
  color: #707092;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  background-color: transparent;
  transition: 0.3s;
}

.header .header-2-3 .btn-outline:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: 0.3s;
}

.header .header-2-3 .btn-outline:hover div path {
  fill: #ffffff;
  transition: 0.3s;
}

.header .header-2-3 .right-column {
  width: 100%;
}

@media (min-width: 576px) {
  .header .header-2-3 .modal-item .modal-dialog {
    max-width: 95%;
    border-radius: 12px;
  }

  .header .header-2-3 .navbar {
    padding: 3rem 2rem;
  }

  .header .header-2-3 .hero {
    padding: 3rem 2rem 5rem;
  }

  .header-2-3 .title-text-big {
    font-size: 3rem;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .header .header-2-3 .navbar {
    padding: 3rem 4rem;
  }

  .header .header-2-3 .hero {
    padding: 3rem 4rem 5rem;
  }

  .header .header-2-3 .left-column {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .header .header-2-3 .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .header .header-2-3 .navbar {
    padding: 3rem 6rem;
  }

  .header .header-2-3 .hero {
    padding: 3rem 6rem 5rem;
  }

  .header .header-2-3 .left-column {
    width: 50%;
    margin-bottom: 0;
  }

  .header .header-2-3 .title-text-big {
    font-size: 2.2rem;
    line-height: 1.2;
  }

  .header .header-2-3 .right-column {
    width: 50%;
  }
}

.navbar img {
  width: 60px;
}