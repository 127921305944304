footer {
  background-color: #113873 !important;
}

.footer-4-2 .icon-footer:hover path {
  fill: #113873;
}

.footer-4-2 .text-gray-1 {
  color: #6681a9;
}

.footer-4-2 .main {
  padding: 4rem 2rem 3rem;
}

.footer-4-2 .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 576px) {
}

/* @media (min-width: 768px) {
  .footer-4-2 .main {
    padding: 4rem 4rem 7rem;
  }
}

@media (min-width: 992px) {
  .footer-4-2 .main {
    padding: 4rem 7rem 7rem;
  }
} */

@media (min-width: 1200px) {
  .footer-4-2 .topnav-centered p {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
