/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #51576d;
  --card-background-lighter-color: #7a7f92;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

.MuiButton-contained {
  background: url("./assets/images/button.png") !important ;
}
