.roadmap {
  background-color: #141432;
  position: relative;
}

/* Timeline Container */
.roadmap .timeline {
  margin: 20px auto;
  padding: 20px;
}

/* Outer Layer with the timeline border */
.roadmap .outer {
  border-left: 5px solid #ec8222;
}

/* Card container */
.roadmap .card {
  position: relative;
  margin: 0 0 20px 16px;
  padding: 10px;
  background: none;
  border: none;
}

/* Information about the timeline */
.roadmap .info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Title of the card */
.roadmap .title {
  color: #ec8222;
  position: relative;
}

/* Timeline dot  */
.roadmap .title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  left: -39px;
  border: 10px solid #ec8222;
}

.roadmap .right {
  margin-top: 20px;
}

.roadmap img {
  border-radius: 10px;
}

/* responsive */

.roadmap .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 1115px) {
  .roadmap {
    scroll-margin-top: 6rem;
  }
}

@media only screen and (max-width: 500px) {
  .roadmap img {
    width: 50%;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
  }
  .roadmap h1 {
    text-align: center;
  }
}
