.faq {
  background-color: #141432;
  padding-bottom: 0;
}

.faq_title {
  color: var(--maincolor);
  font-family: var(--titlefonts);
  font-weight: bold;
  font-size: 4rem;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-bottom: 3rem;
}
.faq_title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -16px;
  height: 3px;
  width: 8%;
  background: var(--textcolor);
  transform: translate(-50%);
  border-radius: 20px;
}

.bottom_img {
  width: 190px;
}

.active_border {
  border: 1px solid var(--maincolor) !important;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button {
  color: black;
  background-color: #fefbf1;
  border-radius: 8px;
}

.accordion-body {
  padding: 1rem 1.25rem;
  background: #fefbf1;
  color: black;
  margin-top: -10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #ca8345;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-item {
  background-color: #ca8345;
  margin: 1rem 2rem;
  border-radius: 8px;
  transition: 300ms;
}

.accordion-item:not(:first-of-type) {
  background-color: var(--mainbg);
}

.accordion-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(8px - 1px);
  border-top-right-radius: calc(8px - 1px);
}

.accordion-button::after {
  content: "+";
  background-image: none;
  font-size: 29px;
  color: #666666;
  display: flex;
  align-items: center;
}
.accordion-button:not(.collapsed)::after {
  content: "";
  background-image: none;
  transform: rotate(-180deg);
  background-image: url("../../assets/images/Close.png");
}

.faq .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1.5rem;
}
