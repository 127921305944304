@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@font-face {
  font-family: Chapeau;
  src: url(/static/media/Chapeau-Light.990d7084.woff);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Chapeau", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #141432;
}

p {
  font-size: 1rem;
  text-align: justify;
}

h1 {
  font-weight: bold;
}

.t-shadow {
  text-shadow: 2px 2px #353030;
}

/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #51576d;
  --card-background-lighter-color: #7a7f92;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

h2,
p {
  color: #f7f6f4;
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

.MuiButton-contained {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQQAAAA9CAYAAABLChfbAAABWElEQVR4nO3dPW6DQBRGUSZy6yJNNuDGm8l2swX2QkPjOkRR5E/5wZnY1kgoc05JgQziXTEC4fL68rwMQK/KaRxz7A8uA+BMEIAQBCAEAYidUwH9KtO87A/HHL87BCAEAYjqkqFM849ta5anx5WtdX/dP/xXrWfnmv27QwBCEIAQBCAEAQhBAEIQgBAEIAQBiOqLSbe+NHHmxSP43aUZqc3evbO5xh0CEIIAhCAAIQhACAIQggCEIAAhCEAIAhCCAIQgACEIQAgCEIIAhCAAIQhACAIQggCEIAAhCEAIAhCCAET1M+yXPhH9XYtPQkMPbp2dFrNZDcK9aj/G/zbAdlgyACEIQAgCEIIAhCAAIQhANH/sWOP9BdiOahAMLGxTi9m0ZABCEIAQBCAEAQhBAEIQgHh/7Fg+byjTvDg90KfdaRy/HPj+cHQpQKcsGYAQBCAEAfgwDMMbjQYssua6z8gAAAAASUVORK5CYII=) !important ;
}

.header .header-2-3 .modal-backdrop.show {
  background-color: #707092;
  opacity: 0.6;
}

.header .header-2-3 .modal-item.modal {
  top: 2rem;
}

.header .header-2-3 .navbar,
.header .header-2-3 .hero {
  padding: 3rem 2rem;
}

.header .header-2-3 .navbar-dark .navbar-nav .nav-link {
  font: 300 18px/1.5rem Poppins, sans-serif;
  color: #707092;
  transition: 0.3s;
}

.header .header-2-3 .navbar-dark .navbar-nav .nav-link:hover {
  font: 600 18px/1.5rem Poppins, sans-serif;
  color: #e7e7e8;
  transition: 0.3s;
}

.header .header-2-3 .navbar-dark .navbar-nav .active>.nav-link,
.header .header-2-3 .navbar-dark .navbar-nav .nav-link.active,
.header .header-2-3 .navbar-dark .navbar-nav .nav-link.show,
.header .header-2-3 .navbar-dark .navbar-nav .show>.nav-link {
  font-weight: 600;
  transition: 0.3s;
}

.header .header-2-3 .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header .header-2-3 .btn:focus,
.header .header-2-3 .btn:active {
  outline: none !important;
}

.header .header-2-3 .btn-fill {
  font: 600 18px / normal Poppins, sans-serif;
  background-color: #524eee;
  border-radius: 12px;
  padding: 12px 32px;
  transition: 0.3s;
}

.header .header-2-3 .btn-fill:hover {
  --tw-shadow: inset 0 0px 25px 0 rgba(20, 20, 50, 0.7);
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: 0.3s;
}

.header .header-2-3 .btn-no-fill {
  font: 300 18px/1.75rem Poppins, sans-serif;
  color: #e7e7e8;
  padding: 12px 32px;
  transition: 0.3s;
}

.header-2-3 .btn-no-fill:hover {
  color: #e7e7e8;
}

.header-2-3 .modal-item .modal-dialog .modal-content {
  border-radius: 8px;
  transition: 0.3s;
}

.header-2-3 .responsive li a {
  padding: 1rem;
  transition: 0.3s;
}

.header-2-3 .left-column {
  margin-bottom: 2.75rem;
  width: 100%;
}

.header-2-3 .text-caption {
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.025em;
  font-family: "Chapeau", sans-serif;
}

.header .header-2-3 .title-text-big {
  font: 600 2rem/2.2.5rem Poppins, sans-serif;
  color: #cbcbd2;
}

.header .header-2-3 .btn-try {
  font: 600 1rem/1.5rem Poppins, sans-serif;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  background-color: #524eee;
  transition: 0.3s;
}

.header .text-gray-1 {
  color: #adadad;
}

.header .header-2-3 .btn-try:hover {
  --tw-shadow: inset 0 0px 25px 0 rgba(20, 20, 50, 0.7);
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: 0.3s;
}

.header .header-2-3 .btn-outline {
  font: 400 1rem/1.5rem Poppins, sans-serif;
  border: 1px solid #707092;
  color: #707092;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  background-color: transparent;
  transition: 0.3s;
}

.header .header-2-3 .btn-outline:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: 0.3s;
}

.header .header-2-3 .btn-outline:hover div path {
  fill: #ffffff;
  transition: 0.3s;
}

.header .header-2-3 .right-column {
  width: 100%;
}

@media (min-width: 576px) {
  .header .header-2-3 .modal-item .modal-dialog {
    max-width: 95%;
    border-radius: 12px;
  }

  .header .header-2-3 .navbar {
    padding: 3rem 2rem;
  }

  .header .header-2-3 .hero {
    padding: 3rem 2rem 5rem;
  }

  .header-2-3 .title-text-big {
    font-size: 3rem;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .header .header-2-3 .navbar {
    padding: 3rem 4rem;
  }

  .header .header-2-3 .hero {
    padding: 3rem 4rem 5rem;
  }

  .header .header-2-3 .left-column {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .header .header-2-3 .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .header .header-2-3 .navbar {
    padding: 3rem 6rem;
  }

  .header .header-2-3 .hero {
    padding: 3rem 6rem 5rem;
  }

  .header .header-2-3 .left-column {
    width: 50%;
    margin-bottom: 0;
  }

  .header .header-2-3 .title-text-big {
    font-size: 2.2rem;
    line-height: 1.2;
  }

  .header .header-2-3 .right-column {
    width: 50%;
  }
}

.navbar img {
  width: 60px;
}
.about {
  position: relative;
  background-color: #27214e;
}

.about .content-8-3 .frame {
  position: relative;
}

.about .content-8-3 .frame::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-sizing: border-box;
  border: 2px solid #fff;
  transform: translate(-30px, 22px);
  transition: transform 250ms ease;
}

.about .content-8-3 .frame:hover::before {
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: translate(0px, 0px);
}

.about .content-8-3 .btn-read {
  color: #ffffff;
  font-weight: 700;
  padding: 1.25rem 1.25rem 1.25rem 0rem;
  letter-spacing: 0.05em;
  transition: 0.2s;
}

.about .content-8-3 .btn-read:hover {
  color: #d75a00;
  transition: 0.2s;
}

.about .content-8-3 .btn-read:hover svg path {
  fill: #d75a00;
  transition: 0.2s;
}

.about .content-8-3 .text-gray-1 {
  color: #adadad;
}

.about .content-8-3 .main {
  padding: 2.5rem 2rem 2rem;
}

.about .content-8-3 .left-column,
.about .content-8-3 .right-column {
  width: 100%;
}

.about .content-8-3 .right-column {
  margin-top: 6rem;
}

.about .content-8-3 .caption-top {
  font: normal 1.25rem/1.75rem Poppins, sans-serif;
  margin-bottom: 1rem;
}

.about .content-8-3 .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1.5rem;
}

.about .content-8-3 .caption-text {
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.025em;
  margin-bottom: 2.25rem;
}

/* svg */
.custom-shape-divider-top-1647973182 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1647973182 svg {
  position: relative;
  display: block;
  width: calc(135% + 1.3px);
  height: 87px;
}

.custom-shape-divider-top-1647973182 .shape-fill {
  fill: #141432;
}

/* svg 2 */

.custom-shape-divider-bottom-1648017657 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1648017657 svg {
  position: relative;
  display: block;
  width: calc(150% + 1.3px);
  height: 80px;
}

.custom-shape-divider-bottom-1648017657 .shape-fill {
  fill: #141432;
}

/* responsive */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .about .content-8-3 .main {
    padding: 2.5rem 4rem 2rem;
  }
}

@media (min-width: 992px) {
  .about .content-8-3 .main {
    padding: 6rem 6rem 8rem;
  }

  .about .content-8-3 .left-column,
  .about .content-8-3 .right-column {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .about .content-8-3 .main {
    padding: 6rem 9rem 8rem;
  }
}

@media (max-width: 1024px) {
  .about .content-8-3 .frame::before {
    width: 100%;
    height: 100%;
    transform: translate(-10px, 10px);
    transition: transform 250ms ease;
  }
}

footer {
  background-color: #113873 !important;
}

.footer-4-2 .icon-footer:hover path {
  fill: #113873;
}

.footer-4-2 .text-gray-1 {
  color: #6681a9;
}

.footer-4-2 .main {
  padding: 4rem 2rem 3rem;
}

.footer-4-2 .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 576px) {
}

/* @media (min-width: 768px) {
  .footer-4-2 .main {
    padding: 4rem 4rem 7rem;
  }
}

@media (min-width: 992px) {
  .footer-4-2 .main {
    padding: 4rem 7rem 7rem;
  }
} */

@media (min-width: 1200px) {
  .footer-4-2 .topnav-centered p {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.roadmap {
  background-color: #141432;
  position: relative;
}

/* Timeline Container */
.roadmap .timeline {
  margin: 20px auto;
  padding: 20px;
}

/* Outer Layer with the timeline border */
.roadmap .outer {
  border-left: 5px solid #ec8222;
}

/* Card container */
.roadmap .card {
  position: relative;
  margin: 0 0 20px 16px;
  padding: 10px;
  background: none;
  border: none;
}

/* Information about the timeline */
.roadmap .info {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

/* Title of the card */
.roadmap .title {
  color: #ec8222;
  position: relative;
}

/* Timeline dot  */
.roadmap .title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  left: -39px;
  border: 10px solid #ec8222;
}

.roadmap .right {
  margin-top: 20px;
}

.roadmap img {
  border-radius: 10px;
}

/* responsive */

.roadmap .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 1115px) {
  .roadmap {
    scroll-margin-top: 6rem;
  }
}

@media only screen and (max-width: 500px) {
  .roadmap img {
    width: 50%;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
  }
  .roadmap h1 {
    text-align: center;
  }
}

.faq {
  background-color: #141432;
  padding-bottom: 0;
}

.faq_title {
  color: var(--maincolor);
  font-family: var(--titlefonts);
  font-weight: bold;
  font-size: 4rem;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-bottom: 3rem;
}
.faq_title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -16px;
  height: 3px;
  width: 8%;
  background: var(--textcolor);
  transform: translate(-50%);
  border-radius: 20px;
}

.bottom_img {
  width: 190px;
}

.active_border {
  border: 1px solid var(--maincolor) !important;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button {
  color: black;
  background-color: #fefbf1;
  border-radius: 8px;
}

.accordion-body {
  padding: 1rem 1.25rem;
  background: #fefbf1;
  color: black;
  margin-top: -10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #ca8345;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-item {
  background-color: #ca8345;
  margin: 1rem 2rem;
  border-radius: 8px;
  transition: 300ms;
}

.accordion-item:not(:first-of-type) {
  background-color: var(--mainbg);
}

.accordion-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(8px - 1px);
  border-top-right-radius: calc(8px - 1px);
}

.accordion-button::after {
  content: "+";
  background-image: none;
  font-size: 29px;
  color: #666666;
  display: flex;
  align-items: center;
}
.accordion-button:not(.collapsed)::after {
  content: "";
  background-image: none;
  transform: rotate(-180deg);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADESURBVHgBjZLNDcMgDIVNDpmDVbJBR8ghf7ekk/SaIyN0g67CGIgDql8FFSUm6ZMQ6JkPGwwRaxzHte97TRfCnnmeV6zVNE0bzw8e1nvfGWNsDWrb9sVLzePesJ6AYCAgZS4gC0YhsCyLDiF8A3lmAer2fbcqnSrB8CXoc8e8pBKO9gE6gAJMEgQ1Jeico3+8n4zlQ+Sllq1SNQjlwa+9tqpB6U61VqkzKEmCG4ZuZxAEL5aOmI4M0TAMG06lC2FP/Nv0BkQrmaJhgk1cAAAAAElFTkSuQmCC);
}

.faq .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1.5rem;
}

body .message {
  background: linear-gradient(
    94.41deg,
    #3b9e8c 0%,
    #174646 52.17%,
    #297d88 100%
  );
  padding: 50px 30px;
}

@media only screen and (min-width: 768px) {
  body .message {
    padding-top: 72px;
    padding-bottom: 89px;
  }
}

body .message .headline {
  font-size: 40px;
  font-weight: 600;
  color: #000909;
}

body .message .sub-headline {
  font-size: 20px;
  font-weight: 400;
  color: #000909;
}

body .message .subscribe {
  margin-top: 75px;
}

body .message .form-subscribe {
  padding: 12px 12px 12px 24px;
  background: #ffffff;
  border-radius: 12px;
  height: 64px;
  width: 459px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

body .message .input-text {
  font-size: 16px;
  font-weight: 600;
}

body .message .btn-subscribe {
  background: linear-gradient(276.78deg, #016a6a 0%, #02a6a6 100%);
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600;
  color: #eeeeee;
}

@media only screen and (max-width: 768px) {
  body .message .btn-subscribe {
    font-size: 12px !important;
  }
}

body .cl-light {
  color: #eeeeee;
}

body .cl-light-grey-2 {
  color: #90bcb7;
}

body .font-lora {
  font-family: "Lora", sans-serif !important;
}

.button_slide {
  color: black;
  border: 2px solid #5969eb;
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #5969eb;
  transition: ease-out 0.4s;
}

.slide_down:hover {
  box-shadow: inset 0 100px 0 0 #5969eb;
}

/* PRELOADER CSS */
.page-loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #272727;
  z-index: 1000;
}
.txt {
  color: #666;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}
/* SPINNER ANIMATION */
.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;

  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

