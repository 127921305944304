@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@font-face {
  font-family: Chapeau;
  src: url("./assets/Chapeau-Light.woff");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Chapeau", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #141432;
}

p {
  font-size: 1rem;
  text-align: justify;
}

h1 {
  font-weight: bold;
}

.t-shadow {
  text-shadow: 2px 2px #353030;
}
